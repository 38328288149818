<script lang="ts">
export default {
  name: 'app-comparator-modal'
};
</script>

<script lang="ts" setup>
import { useModalStore } from '~/store/modal';

const modalStore = useModalStore();

const { hideModal,modal } = modalStore;

</script>

<template lang="pug">
.app-brand-comparator-modal.modal-custom__inner
  .app-brand-comparator-modal__header 
    span.icon-times.ms-auto(@click="hideModal()" :class="{'ico': !modal.props.isBrand ,'ico-brand': modal.props.isBrand}")
  hr.my-3.my-lg-5
  .app-brand-comparator-modal__body(:class="{'text-center': !modal.props.isBrand ,'text-left': modal.props.isBrand}")
    img(v-if="!modal.props.isBrand")(
          src="/images/comparador.png"
          alt="")
    span.icon-comparador(v-else)
    h3.mt-3(:class="{'h3-brand': modal.props.isBrand}") {{modal.props.isBrand ? 'DIRECTO AL COMPARADOR':'¡Directo al comparador!'}}
    p(:class="{'p-brand text-center': !modal.props.isBrand}") Compara los vehículos que necesitas y elige el que mejores de adapta a tus necesidades y gustos
    .row.justify-content-center.mt-4
      .col-12.text-center.mt-3 
        NuxtLink(:to="modal.props.isBrand && modal.props.brand ? `/${modal.props.brand}/panel/comparador/`  :'/panel/comparador/'" @click.prevent="hideModal()" :class="{'btn btn--tertiary btn--large': !modal.props.isBrand ,'btn-brand btn-brand--cta btn-brand--large': modal.props.isBrand}") Ir al comparador
             


      
 
</template>

<style lang="scss">
@import 'index';
</style>